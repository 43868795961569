$().ready(function () {
    $("#usuario").focus();
});

$("#formAuth").validate({
    rules: {
        usuario: { required: true, email: true },
        senha: { required: true }
    },
    messages: {
        usuario: { required: "Campo obrigatório", email: "E-mail inválido" },
        senha: { required: "Campo obrigatório" }
    },
    errorClass: 'is-invalid',
    validClass: "is-valid",
    errorElement: "small",
    errorPlacement: function (error, element) {
        $(element)
            .closest(".input-group")
            .find(".invalid-feedback")
            .append(error);
    },
    submitHandler: function () {
        autenticar();
    }
});

async function autenticar() {
    $("#btnEntrar").attr("disabled", "disabled").html(`<i class="fas fa-circle-notch fa-spin"></i>`);

    try {
        let data = {
            usuario: $("#usuario").val(),
            senha: $("#senha").val()
        };
        let response = await http.post(`${$("head").data("root")}/auth`, data);

        $("#btnEntrar").removeAttr("disabled").html(`Entrar`);

        if (!response.data.status) {
            return Swal.fire({
                icon: "warning",
                title: "Atenção",
                html: response.data.msg,
            });
        }

        location.href = $("head").data("root") + "/dashboard";
    } catch (error) {
        $("#btnEntrar").removeAttr("disabled").html(`Entrar`);
        catchDefault(error);
    }
}